// These are the default "free" clients any user is allowed to see
export const Feeds = [
  {
    value: 'covid19',
    label: 'Covid 19'
  // },
  // {
  //   value: 'corona_beer',
  //   label: 'Corona Beer'
  // },
  // {
  //   value: 'grey_goose',
  //   label: 'Grey Goose'
  }
]

// TODO: remove
export const Colors = [
  '#ff854f',
  '#51beec',
  '#937ac8',
  '#ff86ab',
  '#ff86ab',
  '#ff86ab',
  '#d26068',
  '#59A990',
  '#4adf8e',
  '#df4aa5',
  '#FFA214',
  '#b48743',
  '#4489f1',
  '#e6cd22',
  '#5f5ccb'
]

// TODO: remove
// export const Icons = [
//   'account_balance',
//   'house',
//   'attach_money',
//   'next_week',
//   'next_week',
//   'next_week',
//   'forum',
//   'flight',
//   'local_bar',
//   'stars',
//   'healing',
//   'favorite',
//   'shopping_cart',
//   'build',
//   'drive_eta'
// ]